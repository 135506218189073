import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <div className="c-header">
        <h3>Contact us</h3>
        <p>
          A feeling You have never experienced before. Get hold of a future
          technology now
        </p>
      </div>

      <p className="contact-disci">
        Whatever may be your requirement - be it a simple website design, a
        complex data driven web application development, an ecommerce website, a
        native or cross platform mobile app development, a logo and brand
        identity design, a video production or a full fledged digital marketing
        campaign - we have a solution for you.
      </p>

      <div className="c-right">
        <div className="r-1">
          <i className="fas fa-map-marker-alt"></i>
          <h3>Our Address</h3>
          <p>Noida, Uttar Pradesh, India</p>
        </div>
        <div className="r-1">
          <i className="far fa-envelope"></i>
          <h3>Email us</h3>
          <p>info@projixic.com</p>
        </div>
        <div className="r-1">
          <i className="fas fa-phone-square"></i>
          <h3>Call us</h3>
          <p>+91-8960009752</p>
        </div>
      </div>

      <div className="c-Location">
        <h2>Our Location</h2>
        <p>
          We Work Remotely During Pandemic, On Regular Days We Operate From
          Noida/Vanarasi, U.P.  INDIA.
        </p>
        
        <img src={require('../../assets/c.work.png')} alt="home" />
      </div>
    </div>
  );
};

export default Contact;
