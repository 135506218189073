import React from "react";
import Features from "../../containers/Features/Features";
//import Service from "../Service/Service";
import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  return (
    <>
      <div className="h">
        <div className="h-main">
          <div className="h-info">
            <h1>
              Your new digital <br /> experience with us
            </h1>
            <samp>
              We build Web, Mobile, IOT & Machine Learning Solutions using
              cutting edge open source technologies
            </samp>
            <br />
            <Link to="/services">
              <button>Get Started</button>
            </Link>
            {/* <button>Get Started</button> */}
          </div>
          <div className="pro">
            <img src={require("../../assets/projixic.png")} alt="logo" />
          </div>
        </div>

        <div className="clients">
          <img src={require("../../assets/myob.png")} alt="myob" />
          <img src={require("../../assets/belimo.png")} alt="myob" />
          <img src={require("../../assets/lifegroups.png")} alt="myob" />
          <img src={require("../../assets/lilly.png")} alt="myob" />
          <img src={require("../../assets/citrus.png")} alt="myob" />
          <img src={require("../../assets/trustly.png")} alt="myob" />
        </div>
      </div>
      <Features />
    </>
  );
};

export default Home;
