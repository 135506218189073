import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
       <img src={require('../../assets/logo.png')} alt="footer-logo"/>
      <p>
        Now you will never miss out on what else is new in the world of
        technology.
      </p>
      <div className="social-icon">
        <a href="https://twitter.com/Projixictech" target="blank">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://www.facebook.com/projixic" target="blank">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://instagram.com/projixic" target="blank">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="/">
          <i className="fab fa-skype"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/projixic-technologies-pvt-ltd"
          target="blank"
        >
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
      <div className="end-intro">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Projixic </span>
          </strong>
          Technologies All Rights Reserved
        </div>
        <div className="credits">
          Designed by{" "}
          <a href="http://www.projixic.com/">Projixic Technologies</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
