import React from "react";
import "./Service.css";
import Portfolio from "../../containers/Portfolio/Portfolio";

const Service = () => {
  return (
    <>
      <div className="service">
        <div className="s-hearder">
          <h2>Services</h2>
        </div>
        <p>
          Projixic offers a full stack of software for marketing, sales, and
          customer service, with a completely free CRM at its core. They’re
          powerful alone — but even better when used together. We offer a wide
          array of IT and IT-enabled services based on the requirement of our
          client. We use structured methodologies with proven IT processes that
          help abundantly to reduce the risks or complexity
        </p>
      </div>
      <Portfolio />
    </>
  );
};

export default Service;
