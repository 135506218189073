import React from "react";
import "./Portfolio.css";

const Portfolio = () => {
  return (
    <div className="portfolio">
      <h2> 
        Portfolio
      </h2>
      <p className="p-header">
        At Projixic, We believe that our client’s success is our success. We
        cherish every client relationship with passion, responsibility and
        enthusiasm which is the motivation behind, why most of the our customers
        have been with us since our existence.
      </p>
      <ul>
        <h3>App</h3>
        <h3>Card</h3>
        <h3>Web</h3>
        <h3>Design</h3>
      </ul>
      <img src={require("../../assets/web.png")} alt="web" />
      <img src={require("../../assets/web.png")} alt="web" />

      <div className="team">
        <h2>TEAM</h2>
        <p>
          We are a team of seasoned professionals with extensive experience and
          proven track record in our area of expertise.
        </p>
      </div>
      <div className="frequenty">
        <h2>FREQUENTLY ASKED QUESTIONS</h2>
        <div className="f1">
          <p>
            {" "}
            <i className="far fa-question-circle"></i>Non consectetur a erat nam
            at lectus urna duis?
          </p>
          <p>
            Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
            volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna
            fringilla urna porttitor rhoncus dolor purus non.
          </p>
        </div>
        <div className="f1">
          <p>
            <i className="far fa-question-circle"></i>Non consectetur a erat nam
            at lectus urna duis?
          </p>
          <p>
            Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
            volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna
            fringilla urna porttitor rhoncus dolor purus non.
          </p>
        </div>
        <div className="f1">
          <p>
            <i className="far fa-question-circle"></i>Non consectetur a erat nam
            at lectus urna duis?
          </p>
          <p>
            Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
            volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna
            fringilla urna porttitor rhoncus dolor purus non.
          </p>
        </div>
        <div className="f1">
          <p>
            <i className="far fa-question-circle"></i>Non consectetur a erat nam
            at lectus urna duis?
          </p>
          <p>
            Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
            volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna
            fringilla urna porttitor rhoncus dolor purus non.
          </p>
        </div>
        <div className="f1">
          <p>
            <i className="far fa-question-circle"></i>Non consectetur a erat nam
            at lectus urna duis?
          </p>
          <p>
            Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
            volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna
            fringilla urna porttitor rhoncus dolor purus non.
          </p>
        </div>
      </div>
       
    </div>
  );
};

export default Portfolio;
