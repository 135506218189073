import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about">
      <div className="a-header">
        {" "}
        <h2>About us</h2>
      </div>
      <p>
        Projixic offers a full stack of software for marketing, sales, and
        customer service, with a completely free CRM at its core. They’re
        powerful alone — but even better when used together. We offer a wide
        array of IT and IT-enabled services based on the requirement of our
        client. We use structured methodologies with proven IT processes that
        help abundantly to reduce the risks or complexity
      </p>
      <div className="a-container">
        <div className="a-left">
          <img src={require("../../assets/about.jpg")} alt="about" />
        </div>
        <div className="a-right">
          <div className="r-build">
            <h1>We Build Software For Modern Platforms</h1>
            <samp className="a-line">
              We offer high quality product development services to our clients
              coupled with fast execution speed.
            </samp>
            <div className="a-info">
              <div className="a-i">
                <div className="design">
                  <h3>Design</h3>
                  <samp>
                    Product Design Workshop Prototyping & Wireframing Branding &
                    Visual Design
                  </samp>
                </div>
                <div className="design">
                  <h3>Development</h3>
                  <samp>
                    Mobile App Development Web & Enterprise App Development IOT
                    & Machine Learning Solutions
                  </samp>
                </div>
              </div>
              <div className="a2">
                <div className="design">
                  <h3>Cloud Infrastructure</h3>
                  <samp>
                    Cloud Migration Infrastructure Management & Support Managed
                    Services
                  </samp>
                </div>
                <div className="erp-design">
                  <h3>ERP Soulutions</h3>
                  <samp>
                    A full service provider of SAP solutions, we offer our
                    customers a broad range of SAP consulting and support
                    services.
                  </samp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
