import React from "react";
import "./Features.css";

const Features = () => {
  return (
    <div className="feature">
      <h1>FEATURES</h1>
      <samp className="f-p">
        Projixic Technologies Offering revolutionizing technology solutions to
        make your business smarter and fast.
      </samp>
      <div className="f-mobile-app">
        <div className="mobile">
          <img src={require("../../assets/features-1.png")} alt="mobile" />
        </div>
        <div className="m-line">
          <h2>Mobile Application Development Services.</h2>
          <p>
            With our Mobile App Development Services grow your market, scale to
            wider business verticals, and create new revenue streams. We, at
            Projixic, offer comprehensive native and hybrid mobile app
            development services .ranging from application architecture,
            strategy, development, modernization, optimization, maintenance and
            more.
          </p>
          <ul>
            <li><i className="fas fa-arrow-right"></i>Native App Development</li>
            <li><i className="fas fa-arrow-right"></i>Hybrid App Development</li>
            <li><i className="fas fa-arrow-right"></i>Progressive Web App Development</li>
          </ul>
        </div>
      </div>
      <div className="feature-2">
        <div className="f2-info">
          <h2>SaaS Application Development</h2>
          <p>
            With Projixic, reinvent your business with SaaS application
            development services. We help you place innovation at the heart of
            your business to empower you to move faster, scale faster, make
            informed and data-backed decisions, and reduce operational costs. We
            help you build an extension to your existing web property or a
            stand-alone app with robust process automation capabilities. Be
            future-ready, achieve scalability, and robust security with our saas
            solutions.
          </p>
        </div>
        <div className="f2-saas">
          <img src={require("../../assets/features-2.png")} alt="mobile" />
        </div>
      </div>
      <div className="f3">
        <div className="f3-web">
          <img src={require("../../assets/features-3.png")} alt="mobile" />
        </div>
        <div className="f3-info">
          <h2>Web Development / Web Application Development</h2>
          <p>
            We have an excellent team of web development experts who are
            constantly experimenting and implementing innovative ideas that will
            transform website designs into a gold mine for your business.
          </p>
          <ul>
            <li><i className="fas fa-arrow-right"></i>Ruby Development</li>
            <li><i className="fas fa-arrow-right"></i>.Net Development</li>
            <li><i className="fas fa-arrow-right"></i>Python Development</li>
            <li><i className="fas fa-arrow-right"></i>Java Development</li>
          </ul>
        </div>
      </div>
      <div className="f4">
        <div className="f4-info">
          <h2>DevOps Services</h2>
          <p>
            Our end-to-end DevOps servicesenable businesses to accelerate their
            development cycle and strike a perfect balance between development
            and IT operations. We are a seasoned DevOps service provider company
            that streamlines the software development processes like planning,
            analysis, design, development, testing, integration, and
            maintenance. Our DevOps solutions improve work culture through lean
            and agile principles, remove development bottlenecks through a
            highly fluid and collaborative environment, enable responsive
            feedback mechanism, enhance operational performance, and thus foster
            innovation.
          </p>
        </div>
        <div className="f4-dev">
          <img src={require("../../assets/features-4.png")} alt="mobile" />
        </div>
      </div>
    </div>
  );
};

export default Features;
