import React from "react";
import "./Technology.css";

const Technology = () => {
  return (
    <div className="tech">
      <div className="t-header">
        <h3>Technologies</h3>
        <p>
          A feeling You have never experienced before. Get hold of a future
          technology now
        </p>
      </div>
      <div className="back-end">
        <h2>Mobile Technologies</h2>
        <img src={require("../../assets/reactnative.png")} alt="react" />
        <img src={require("../../assets/kotlin.png")} alt="kotlin" />
        <img src={require("../../assets/flutter.png")} alt="flutter" />
      </div>
      <div className="back-end">
        <h2>Front End Development</h2>
        <img src={require("../../assets/react1.png")} alt="pytghon" />
        <img src={require("../../assets/vue.jpeg")} alt="pytghon" />
        <img src={require("../../assets/angular.png")} alt="pytghon" />
      </div>

      <div className="back-end">
        <h2>Back End Development</h2>
        <img src={require("../../assets/python.png")} alt="python" />
        <img src={require("../../assets/rails.png")} alt="rails" />
        <img src={require("../../assets/nodejs.png")} alt="node-js" />
      </div>

      <div className="back-end">
        <h2>DevOps</h2>
        <img src={require("../../assets/serverless.png")} alt="pytghon" />
        <img src={require("../../assets/docker.png")} alt="pytghon" />
        <img src={require("../../assets/Kubernetes.png")} alt="pytghon" />
      </div>
    </div>
  );
};

export default Technology;
