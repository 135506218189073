import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Footer from "./containers/Footer/Footer";
import About from "./components/About/About";
import Features from "./containers/Features/Features";
import Contact from "./components/Contact/Contact";
import Service from "./components/Service/Service";
import Navbar from "./components/Navbar/Navbar";
import Login from "./containers/Login/Login";
import Technology from "./components/Technology/Technology";
//import Blog from "./components/Blog/Blog";
 

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/services" element={<Service />} />
          <Route path="/features" element={<Features />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/log-in" element={<Login />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
